<template>
  <div>
    <v-card class="mb-4">
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-list dense>
              <v-list-item>
                <v-list-item-title>Venue Name</v-list-item-title>
                <v-list-item-subtitle>{{ data.name }}</v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>Address</v-list-item-title>
                <v-list-item-subtitle>{{ address }}</v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>Country</v-list-item-title>
                <v-list-item-subtitle>{{ data.address.country }}</v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>Postal Code</v-list-item-title>
                <v-list-item-subtitle>{{ data.address.postcode }}</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="data.event && data.event.title">
                <v-list-item-title>Event title</v-list-item-title>
                <v-list-item-subtitle>{{ data.event.title }}</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="data.event && data.event.startsAt">
                <v-list-item-title>Event Start Date</v-list-item-title>
                <v-list-item-subtitle>{{ data.event.startsAt | dateTime }}</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="data && data.event && data.event.endsAt">
                <v-list-item-title >Event End Date</v-list-item-title>
                <v-list-item-subtitle>{{ data.event.endsAt | dateTime }}</v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="$user.organiser === data.organiser">
                <v-list-item-title v-if="data.event">
                  <v-btn block color="primary" @click="edit()">Edit</v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="8">
            <v-responsive :aspect-ratio="16 / 9">
              <div id="map" style="height:100%"></div>
            </v-responsive>
          </v-col> -->
        </v-row>
        <v-row v-if="data.information">
          <v-col cols="12" sm="12" md="12">
            <v-card-title>Venue Information</v-card-title>
            <v-card-text v-html="data.information"> </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { VenueService } from '@services';
export default {
  data() {
    return {
      text: 'Create',
      loading: false,
      address: '',
      data: {
        event: {},
        address: {},
        place: '',
        status: 'pending',
        organiser: '',
        map: null,
      },
    };
  },
  created() {
    this.data.organiser = this.$user.organiser;
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getVenueById(this.$route.params.id);
    }
  },
  async mounted() {
    if (!window.google?.maps) {
      // key=${process.env.VUE_APP_MAP}&libraries=places,geometry
      await this.$loadScript(`https://maps.googleapis.com/maps/api/js?libraries=places`);
    }
    this.initMap();
  },
  methods: {
    async getVenueById(id) {
      this.data = await VenueService.getById(id);
      this.address = `${this.data.address.line1} ${this.data.address.line2} ${this.data.address.city}`
    },
    edit() {
      this.$next({ name: 'promoter.venues.edit', params: { id: this.data._id } });
    },
    async save() {
      this.loading = true;
      if (this.data._id) {
        await VenueService.update(this.data);
        this.$next({ name: 'promoter.venues' });
      } else {
        await VenueService.save(this.data);
        this.$next({ name: 'promoter.venues' });
      }
      this.loading = false;
    },
    initMap() {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
      });

      const marker = new window.google.maps.Marker({
        position: { lat: -34.397, lng: 150.644 },
        map,
        title: 'Hello World!',
      });

      console.log(marker);
    },
  },
};
</script>
